const components = {
  MuiCssBaseline: {
    styleOverrides: {
      "*": {
        boxSizing: "border-box"
      },
      html: {
        height: "100%",
        width: "100%"
      },
      body: {
        height: "100%",
        margin: 0,
        padding: 0,
        color: "#fff !important",
        backgroundColor: "#720aaf",
        backgroundImage: "url('https://www.irflexapp.com/img/home3/movil_irflexcoin.png')",
        backgroundPosition: "-100px 60px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundAttachment: "fixed"
      },
      "#root": {
        height: "100%",
        color: "#fff !important"
      }
    }
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        marginTop: 32,
        paddingLeft: "15px !important",
        paddingRight: "15px !important"
      }
    }
  },
  MuiCard: {
    styleOverrides: {
      root: {
        padding: "32px 32px",
        background: "#fff",
        borderRadius: 10,
        overflow: "hidden",
        color: "#000",
        boxShadow: "0px 7px 30px 0px rgba(90, 114, 123, 0.11)"
      }
    }
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: "rgb(255 255 255 / 12%)"
      }
    }
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        color: "#000",
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "#720aaf"
        },
        "&.Mui-hoover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#fff"
        }
      }
    }
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        color: "#000"
      }
    }
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: "#000"
      }
    }
  }
};

export default components;
