import React from "react";
import { BrowserRouter } from "react-router-dom";

import { Binance } from "@thirdweb-dev/chains";

import {
  ThirdwebProvider,
  rainbowWallet,
  trustWallet,
  safeWallet,
  metamaskWallet,
  coinbaseWallet
} from "@thirdweb-dev/react";
import { createRoot } from "react-dom/client";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./config/theme";

import App from "./App";

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThirdwebProvider
        autoConnect={true}
        activeChain={Binance}
        clientId="b05c8326e350f823ed4c0ec3efd46215"
        supportedWallets={[metamaskWallet(), coinbaseWallet(), rainbowWallet(), trustWallet(), safeWallet()]}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>{" "}
      </ThirdwebProvider>
    </BrowserRouter>
  </React.StrictMode>
);
