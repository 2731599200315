import React from "react";
import "./Arbol.css";
import { getEllipsisTxt } from "utils/formatters";

const Nodo = ({ nombre, hijos }) => {
  return (
    <ul>
      <li>
        {" "}
        <span> {nombre}</span>
        {hijos && hijos.length > 0 && (
          <ul className="hijos">
            {hijos.map((hijo, index) => (
              <li>
                {" "}
                <span>
                  {" "}
                  <Nodo key={index} {...hijo} />
                </span>
              </li>
            ))}
          </ul>
        )}
      </li>
    </ul>
  );
};

const Arbol = ({ nombres, niveles }) => {
  const construirArbol = () => {
    let raiz = { nombre: getEllipsisTxt(nombres[0]), hijos: [] };
    let nodos = [raiz];

    for (let i = 1; i < nombres.length; i++) {
      let nuevoNodo = { nombre: getEllipsisTxt(nombres[i]), hijos: [] };
      nodos.push(nuevoNodo);

      for (let j = i - 1; j >= 0; j--) {
        if (Number(niveles[j].toString()) === Number(niveles[i].toString()) - 1) {
          nodos[j].hijos.push(nuevoNodo);
          break;
        }
      }
    }

    return raiz;
  };

  let arbol = construirArbol();

  return (
    <div className="nodo tree">
      <Nodo {...arbol} />
    </div>
  );
};

export default Arbol;
