import { Stack } from "@mui/material";
import React from "react";
import Logo from "../Logo";
import { ConnectWallet } from "@thirdweb-dev/react";

const Header = () => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Logo />
      <ConnectWallet theme={"dark"} />
    </Stack>
  );
};

export default Header;
