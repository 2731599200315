import React from "react";
import { useContractRead, useContract, useContractWrite, Web3Button, useAddress } from "@thirdweb-dev/react";
import { Button, Divider, Stack, Typography } from "@mui/material";
import LabelAndValue from "../LabelAndValue";
import AbxStakingContract from "../../data/abi/irflexTokenPresale.json";
import SmartContract from "../../data/abi/sc.json";
import { parseBigNumberToFloat, getEllipsisTxt } from "utils/formatters";
import "./Arbol.css";
import Arbol from "./Arbol";

const contractAddressAbxStaking = SmartContract.presale;
const YourRewards = ({ label, indexItem, symbol, invest }) => {
  const address = useAddress();
  const { contract } = useContract(contractAddressAbxStaking, AbxStakingContract);
  const { data: treeReferralInfo, isLoading, error } = useContractRead(contract, "treeReferral", [address]);
  function construirDatosArbol(nombres, niveles = [0, 0]) {
    let nodos = nombres.map((nombre, i) => ({ nombre, hijos: [] }));
    let raiz = nodos[0];

    for (let i = 1; i < nodos.length; i++) {
      for (let j = i - 1; j >= 0; j--) {
        if (Number(niveles[j].toString()) === Number(niveles[i].toString()) - 1) {
          nodos[j].hijos.push(nodos[i]);
          break;
        }
      }
    }

    return raiz;
  }
  const nombres = treeReferralInfo?.length > 0 ? treeReferralInfo[0] : [0];
  const niveles = treeReferralInfo?.length > 0 ? treeReferralInfo[1] : [0];

  return (
    <>
      {invest && invest[1].length > 0 && invest[0].length > 0 ? (
        <Stack spacing={1}>
          <Typography variant="h5" gutterBottom>
            Directs
          </Typography>

          {invest[0]?.map((item, key) => (
            <div key={key}>
              <LabelAndValue label="User" value={getEllipsisTxt(item)} symbol="" />
              <LabelAndValue label="Invest" value={invest[1][key].toString() / 1e18 + " USDT"} symbol="" />
              <Divider variant="inset" />{" "}
            </div>
          ))}
          <Typography variant="h5" gutterBottom>
            Tree
          </Typography>
          <Arbol nombres={nombres} niveles={niveles} />
        </Stack>
      ) : (
        <p></p>
      )}
    </>
  );
};

export default YourRewards;
