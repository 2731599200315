import { Stack, Typography } from "@mui/material";
import React from "react";

const LabelAndValue = ({ label, value, symbol }) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="button" display="block" gutterBottom>
        <span className="ramdos"> {label}</span>
      </Typography>
      <Typography textAlign="right" variant="caption" display="block" gutterBottom>
        {value} {symbol}
      </Typography>
    </Stack>
  );
};

export default LabelAndValue;
