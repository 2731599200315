import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useAddress } from "@thirdweb-dev/react";
const ReferralLink = () => {
  const address = useAddress();
  const urlActual = window.location.origin;

  const [textoCopiar, setTextoCopiar] = useState();

  const copiarTextoAlPortapapeles = () => {
    navigator.clipboard
      .writeText(urlActual + "/?ref=" + address)
      .then(() => {
        alert("Texto copiado al portapapeles");
      })
      .catch((err) => {
        console.error("Error al copiar el texto:", err);
      });
  };

  return (
    <Card>
      <Typography variant="h5" gutterBottom>
        Referral Link
      </Typography>
      <Typography variant="body1" gutterBottom m={2}>
        Earn up to 10% commissions using your referral link
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <TextField
            variant="filled"
            value={urlActual + "/?ref=" + address}
            onChange={(e) => setTextoCopiar(urlActual + "/?ref=" + address)}
            fullWidth
            label="Link"
            InputProps={{
              readOnly: true
            }}
            id="ReferralLink"
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={copiarTextoAlPortapapeles}
            fullWidth
            variant="contained"
            placeholder="Escribe algo para copiar"
          >
            Copy
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ReferralLink;
