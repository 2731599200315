import React from "react";
import LabelAndValue from "../LabelAndValue";
import { useContractRead, useContract, useContractWrite, Web3Button, useAddress } from "@thirdweb-dev/react";
import AbxStakingContract from "../../data/abi/irflexTokenPresale.json";
import SmartContract from "../../data/abi/sc.json";

import { parseBigNumberToFloat, getEllipsisTxt } from "utils/formatters";

const contractAddressAbxStaking = SmartContract.presale;

const Overview = () => {
  const address = useAddress();
  const { contract } = useContract(contractAddressAbxStaking, AbxStakingContract);
  const { data: datagetUserInfo, isLoading, error } = useContractRead(contract, "users", [address]);

  const {
    data: treeReferralInfo = [0, 0],
    isLoading: loaderTreeReferral,
    error: errorTreeReferral
  } = useContractRead(contract, "treeReferral", [address]);

  //0x2E77a7dE2024E07f50a45A9f83e952376514586E

  const { data: calculeData = [0, 0], isLoading: load } = useContractRead(
    contract,
    "calculateTotalReferralandVolumenForUser",
    [address]
  );
  console.log("treeReferralInfo", treeReferralInfo);
  return (
    <div>
      <LabelAndValue label="User" value={getEllipsisTxt(address)} symbol="BNB" />
      <LabelAndValue label="My investment" value={datagetUserInfo?.investment.toString() / 1e18} symbol="USDT" />
      <LabelAndValue label="Total X" value={datagetUserInfo?.totalRewards.toString() / 1e18} symbol="USDT" />
      <LabelAndValue label="Referral Volume" value={datagetUserInfo?.volinvestment?.toString() / 1e18} symbol="USDT" />
      <LabelAndValue label="Revenue" value={calculeData[1]?.toString() / 1e16} symbol="USDT" />
      <LabelAndValue label="Upline" value={getEllipsisTxt(datagetUserInfo?.referrer)} />
    </div>
  );
};

export default Overview;
