import React from "react";

const Logo = () => {
  return (
    <div>
      <img
        src="https://www.irflexapp.com/img/logo-header.png"
        sizes="(max-width: 479px) 51vw, 148px"
        srcSet="https://www.irflexapp.com/img/logo-header.png"
        alt=""
        className="header-logo"
      />
    </div>
  );
};

export default Logo;
