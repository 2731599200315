import { Card, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import "./App.css";
import ReferralLink from "./components/ReferralLink";
import { useState } from "react";
import Overview from "./components/Overview";
import YourRewards from "./components/YourRewards";
import Investment from "./components/Investment";
import Bake from "./components/Bake";
import Header from "./components/Header";
import { useContractRead, useContract, useContractWrite, Web3Button, useAddress } from "@thirdweb-dev/react";
import irflexTokenPresaleContract from "./data/abi/irflexTokenPresale.json";
import SmartContract from "./data/abi/sc.json";
import { parseBigNumberToFloat, getEllipsisTxt } from "utils/formatters";

const contractAddressirflexTokenPresaleContract = SmartContract.presale;

function App() {
  const address = useAddress();
  const { contract } = useContract(contractAddressirflexTokenPresaleContract, irflexTokenPresaleContract);
  const { data, isLoading, error } = useContractRead(contract, "getReferrals", [address]);
  const { data: datagetUserInfo, isLoading: loader } = useContractRead(contract, "getReferrals", [address]);

  return (
    <Container>
      <Header />
      <Stack spacing={5} mt={3}>
        <Typography variant="h4" textAlign="center" gutterBottom>
          Presale IrFlex Coin Now
        </Typography>

        <Grid item spacing={2}>
          <Grid item xs={12} md={12}>
            <Card>
              <Stack spacing={2}>
                <Overview />
                <Bake />
                <p>
                  Smart Contract :{" "}
                  <a href={"https://bscscan.com/address/" + contractAddressirflexTokenPresaleContract} target="_blank">
                    {getEllipsisTxt(contractAddressirflexTokenPresaleContract)}
                  </a>{" "}
                </p>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} mt={2}>
            {" "}
            <ReferralLink />
          </Grid>

          <Grid item xs={12} md={12} mt={2}>
            <YourRewards invest={datagetUserInfo} label="Your Rewards" symbol="BNB" />
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
}

export default App;
