import { Box, Button, FormControl, InputAdornment, OutlinedInput, Typography } from "@mui/material";
import { useContractRead, useContract, useContractWrite, Web3Button, useAddress } from "@thirdweb-dev/react";
import { utils } from "ethers";
import abiirflexTokenPresale from "../../data/abi/irflexTokenPresale.json";
import SmartContract from "../../data/abi/sc.json";
import USDTTokenAbi from "../../data/abi/GenericERC20.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { parseBigNumberToFloat, getEllipsisTxt } from "utils/formatters";
import { useLocation } from "react-router-dom";
import { ethers } from "ethers";
const contractAddressirflexTokenPresale = SmartContract.presale;
const contractAddressUSDTToken = "0x55d398326f99059fF775485246999027B3197955";
//abiirflexTokenPresale
import React, { useState } from "react";

const Bake = () => {
  const address = useAddress();
  const location = useLocation();
  const { contract } = useContract(contractAddressirflexTokenPresale, abiirflexTokenPresale);

  const { contract: USDTTokencontract } = useContract(contractAddressUSDTToken, USDTTokenAbi);
  const { mutateAsync: USDTCall } = useContractWrite(USDTTokencontract, "approve");
  const [userName, setHandleUserName] = useState("1000");
  const { mutateAsync: invest } = useContractWrite(contract, "invest");

  const { data, isLoading, error } = useContractRead(contract, "getUserInfo", [address]);

  const permited = useContractRead(USDTTokencontract, "allowance", [address, contractAddressirflexTokenPresale]);

  const searchs = location?.search ? location?.search : window.location.search;
  const udis = searchs.split("=");
  const referall = udis[1];
  const linkudu = referall ? referall : "0x43429b446053c3c6Ed1616B2bF757399B6F72D51";

  const caracterBuscado = "&";
  const contieneCaracter = linkudu?.toLowerCase().includes(caracterBuscado.toLowerCase());

  const newURLsTrus = contieneCaracter ? linkudu.split(caracterBuscado)[0] : linkudu;

  const handleUserName = (e) => {
    setHandleUserName(e.target.value);
  };
  const handlerClickInvestment = async () => {
    try {
      const res = await invest({
        args: [ethers.utils.parseEther(userName), newURLsTrus],
        overrides: {
          value: "22000000000000000"
        }
      });
      toast.success(res.receipt.transactionHash, {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    } catch (err) {
      toast.error(err.reason || "error", {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    }
  };
  const handlerClickApprove = async () => {
    try {
      const res = await USDTCall({ args: [contractAddressirflexTokenPresale, ethers.utils.parseEther("10000000000")] });
      toast.success(res.receipt.transactionHash, {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    } catch (err) {
      toast.error(err.reason || "error", {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    }
  };
  return (
    <Box>
      {permited?.data?.toString() === "0" ? (
        <>
          {" "}
          <Web3Button
            contractAddress={contractAddressirflexTokenPresale}
            contractAbi={abiirflexTokenPresale}
            className="btn-custom-class reinv"
            action={() => handlerClickApprove()}
            theme="dark"
          >
            {" "}
            Approve USDT
          </Web3Button>
          <p>Before making your investment please approve the use of USDT</p>
        </>
      ) : (
        <>
          <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
            <Typography variant="h5" gutterBottom>
              How much token will you acquire in USDT?
            </Typography>
            <OutlinedInput
              id="outlined-adornment-weight"
              type="number"
              value={userName}
              onChange={handleUserName}
              endAdornment={
                <InputAdornment xs={{ color: "#fff" }} position="end">
                  USDT
                </InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight"
              }}
            />
          </FormControl>
          <Web3Button
            contractAddress={contractAddressirflexTokenPresale}
            contractAbi={abiirflexTokenPresale}
            className="btn-custom-class reinv"
            action={() => handlerClickInvestment()}
            theme="dark"
          >
            {" "}
            BUY TOKEN IRFLEX
          </Web3Button>
        </>
      )}
      <ToastContainer
        position="top-center"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
};

export default Bake;
